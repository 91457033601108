<template>
  <div>
    <div class="card card-custom" style="margin-bottom: 3rem">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Nickname" label-for="input-lg">
              <b-form-select id="input-lg" value-field="nickname" text-field="nickname" size="lg"
                v-model="selectNickname">
                <b-form-select-option v-for="(proj, i) in this.projectList" v-bind:key="i"
                  :value="{ code: proj.code, text: proj.nickname }">
                  {{ proj.nickname }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Owner" label-for="input-lg">
              <b-form-input id="input-lg" size="lg" v-model="selectedNickname.ownerProject" disabled></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Project Name" label-for="input-lg">
              <b-form-input id="input-lg" size="lg" v-model="selectedNickname.nameProject" disabled></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Contract Number" label-for="input-lg">
              <b-form-input id="input-lg" size="lg" v-model="selectedNickname.noProject" disabled></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Status" label-for="input-lg">
              <b-form-input id="input-lg" size="lg" v-model="selectedNickname.statusProject" disabled></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="margin-bottom: 3rem">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Station" label-for="input-lg">
              <b-form-select id="input-lg" size="lg" v-model="search.searchStation">
                <b-form-select-option v-for="(stat, i) in this.$store.getters.getterStation" v-bind:key="i"
                  :value="stat.code">
                  {{ stat.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Scope" label-for="input-lg">
              <b-form-select id="input-lg" size="lg" v-model="search.searchScope">
                <b-form-select-option v-for="(scp, i) in this.$store.getters.getterScope" v-bind:key="i"
                  :value="scp.code">
                  {{ scp.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Title" label-for="input-lg">
              <b-form-input id="input-lg" size="lg" class="title_search" v-model="search.searchTitle"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Category" label-for="input-lg">
              <b-form-select id="input-lg" size="lg" :options="categoriesOptions" v-model="search.searchCategory">
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="Design No" label-for="input-lg">
              <b-form-input id="input-lg" size="lg" class="title_search" v-model="search.searchDesignNo"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <p>{{ search.searchDesign }}</p>

            <div class="button-group">
              <button class="btn btn-secondary" @click.prevent="resetTable" v-bind:class="{
                  disabled: disabledReset,
                }">
                <div v-bind:class="{
                  'd-none': loaderReset,
                }">
                  <div class="text-center">
                    <b-spinner variant="secondary" label="Spinning" style="width: 1rem; height: 1rem"></b-spinner>
                  </div>
                </div>
                <i v-if="loaderReset" class="flaticon2-time"></i>{{ resetText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <b-tabs v-model="tabIndex">
          <!-- document tab -->
          <b-tab title="Document" active>
            <div class="card border-0" style="margin-bottom: 3rem">
              <div class="card-body">
                <div class="button-group d-flex justify-content-end mb-4">
                  <template v-if="this.filteredDesign !== undefined &&
                  this.filteredDesign.length > 0
                  ">
                    <input :id="'fileImportExcel'" @input="onFileChange($event)" type="file" hidden />

                    <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-2" @click.prevent="importData"
                      ref="kt_import_data">
                      <i class="fa fa-file-excel"></i>
                      Import
                    </a>

                    <vue-excel-xlsx :data="data.exportList" :file-name="'DocDrafting'" :columns="columns"
                      :sheetname="'sheet1'">
                      <button class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                        :disabled="data.exportList.length === 0">
                        <i class="fa fa-file-excel"></i>
                        Export
                      </button>
                    </vue-excel-xlsx>
                  </template>
                  <button class="btn btn-primary" v-if="this.selectNickname == null" disabled>
                    Load design
                  </button>
                  <button class="btn btn-primary" @click.prevent="loadDesign('DOC')" ref="kt_load_design" v-else>
                    Load design
                  </button>
                </div>

                <complete-table :data="filteredDesign" :loading="loadingData">
                  <template #header>
                    <tr>
                      <th></th>
                      <th>Numbering</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Category</th>
                      <th>Scope</th>
                      <th>Station</th>
                      <th>Delete Design</th>
                    </tr>
                  </template>

                  <template #defaultBody="{ item: data, i }">
                    <tr v-bind:key="i" v-bind:class="{
                  'd-none': tableBody,
                }">
                      <td v-if="data.added == 0">
                        <label class="form-checkbox">
                          <input type="checkbox" :value="data.design_code +
                  '//' +
                  data.numbering +
                  '//' +
                  data.title +
                  '//' +
                  getOptionalValue(data.type, 'code') +
                  '//' +
                  getOptionalValue(data.station, 'code') +
                  '//' +
                  getOptionalValue(data.station, 'name')
                  " v-model="selectedDesign" />
                        </label>
                      </td>
                      <td v-else></td>
                      <td>{{ data.numbering }}</td>
                      <td>{{ data.title }}</td>
                      <td>{{ data.type.code }}</td>
                      <td>
                        {{ data.category != null ? data.category.name : "" }}
                      </td>
                      <td>{{ data.scope_code }}</td>
                      <td>
                        <template>
                          <div v-if="data.station == null">-</div>
                          <div v-else>
                            {{ data.station.name }}
                          </div>
                        </template>
                      </td>
                      <td v-if="data.added == 1">
                        <label class="form-checkbox">
                          <input type="checkbox" :value="data.id" v-model="selectedDeleteDesign" />
                        </label>
                      </td>
                      <td v-else></td>
                    </tr>
                  </template>
                </complete-table>

                <!-- button save -->
                <div v-bind:class="{
                  'd-none': actionDesign,
                }">
                  <div class="button-group d-flex justify-content-end">
                    <button class="btn btn-success" @click.prevent="saveDesign">
                      <div v-bind:class="{
                  'd-none': loaderSaveDesign,
                }">
                        <div class="text-center">
                          <b-spinner variant="secondary" label="Spinning" style="width: 1rem; height: 1rem"></b-spinner>
                        </div>
                      </div>
                      {{ loaderSaveDesignText }}
                    </button>

                    <button class="btn btn-danger ml-2" @click.prevent="deleteDesign">
                      <div class="loader" v-bind:class="{
                  'd-none': loaderDeleteDesign,
                }">
                        <div class="text-center">
                          <b-spinner variant="secondary" label="Spinning" style="width: 1rem; height: 1rem"></b-spinner>
                        </div>
                      </div>
                      {{ loaderDeleteDesignText }}
                    </button>
                  </div>
                </div>
                <!-- end button save -->
              </div>
            </div>
          </b-tab>
          <!-- end document tab -->

          <!-- drawing tab -->
          <b-tab title="Drawing">
            <div class="card border-0" style="margin-bottom: 3rem">
              <div class="card-body">
                <div class="button-group d-flex justify-content-end mb-4">
                  <template v-if="this.filteredDesign !== undefined &&
                  this.filteredDesign.length > 0
                  ">
                    <input :id="'fileImportExcel'" @input="onFileChange($event)" type="file" hidden />

                    <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-2" @click.prevent="importData"
                      ref="kt_import_data">
                      <i class="fa fa-file-excel"></i>
                      Import
                    </a>

                    <vue-excel-xlsx :data="data.exportList" :file-name="'DrwDrafting'" :columns="columns"
                      :sheetname="'sheet1'">
                      <button class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                        :disabled="data.exportList.length === 0">
                        <i class="fa fa-file-excel"></i>
                        Export
                      </button>
                    </vue-excel-xlsx>
                  </template>
                  <button class="btn btn-primary" @click.prevent="loadDesign('DRW')" ref="kt_load_design">
                    Load design
                  </button>
                </div>

                <complete-table :loading="loadingData" :data="filteredDesign">
                  <template #header>
                    <tr>
                      <th></th>
                      <th>Numbering</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Category</th>
                      <th>Scope</th>
                      <th>Station</th>
                      <th>Delete design</th>
                    </tr>
                  </template>

                  <template #defaultBody="{ item: data, i }">
                    <tr v-bind:key="i" v-bind:class="{
                  'd-none': tableBody,
                }">
                      <td v-if="data.added == 0">
                        <label class="form-checkbox">
                          <input type="checkbox" :value="data.design_code +
                  '//' +
                  data.numbering +
                  '//' +
                  data.title +
                  '//' +
                  getOptionalValue(data.type, 'code') +
                  '//' +
                  getOptionalValue(data.station, 'code') +
                  '//' +
                  getOptionalValue(data.station, 'name')
                  " v-model="selectedDesign" />
                        </label>
                      </td>
                      <td v-else></td>
                      <td>{{ data.numbering }}</td>
                      <td>{{ data.title }}</td>
                      <td>{{ data.type.code }}</td>
                      <td>
                        {{ data.category != null ? data.category.name : "" }}
                      </td>
                      <td>{{ data.scope_code }}</td>
                      <td>
                        <template>
                          <div v-if="data.station == null">-</div>
                          <div v-else>
                            {{ data.station.name }}
                          </div>
                        </template>
                      </td>
                      <td v-if="data.added == 1">
                        <label class="form-checkbox">
                          <input type="checkbox" :value="data.id" v-model="selectedDeleteDesign" />
                        </label>
                      </td>
                      <td v-else></td>
                    </tr>
                  </template>
                </complete-table>

                <!-- button save -->
                <div v-bind:class="{
                  'd-none': actionDesign,
                }">
                  <div class="button-group d-flex justify-content-end">
                    <button class="btn btn-success" @click.prevent="saveDesign">
                      <div v-bind:class="{
                  'd-none': loaderSaveDesign,
                }">
                        <div class="text-center">
                          <b-spinner variant="secondary" label="Spinning" style="width: 1rem; height: 1rem"></b-spinner>
                        </div>
                      </div>
                      {{ loaderSaveDesignText }}
                    </button>

                    <button class="btn btn-danger ml-2" @click.prevent="deleteDesign">
                      <div class="loader" v-bind:class="{
                  'd-none': loaderDeleteDesign,
                }">
                        <div class="text-center">
                          <b-spinner variant="secondary" label="Spinning" style="width: 1rem; height: 1rem"></b-spinner>
                        </div>
                      </div>
                      {{ loaderDeleteDesignText }}
                    </button>
                  </div>
                </div>
                <!-- end button save -->
              </div>
            </div>
          </b-tab>
          <!-- end drawing tab -->

          <!-- form tab -->
          <b-tab title="Software">
            <div class="card border-0" style="margin-bottom: 3rem">
              <div class="card-body">
                <div class="button-group d-flex justify-content-end mb-4">
                  <template v-if="this.filteredDesign !== undefined &&
                  this.filteredDesign.length > 0
                  ">
                    <input :id="'fileImportExcel'" @input="onFileChange($event)" type="file" hidden />

                    <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-2" @click.prevent="importData"
                      ref="kt_import_data">
                      <i class="fa fa-file-excel"></i>
                      Import
                    </a>

                    <vue-excel-xlsx :data="data.exportList" :file-name="'ForDrafting'" :columns="columns"
                      :sheetname="'sheet1'">
                      <button class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                        :disabled="data.exportList.length === 0">
                        <i class="fa fa-file-excel"></i>
                        Export
                      </button>
                    </vue-excel-xlsx>
                  </template>
                  <button class="btn btn-primary" @click.prevent="loadDesign('FOR')" ref="kt_load_design">
                    Load design
                  </button>
                </div>

                <complete-table :loading="loadingData" :data="filteredDesign">
                  <template #header>
                    <tr>
                      <th></th>
                      <th>Numbering</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Category</th>
                      <th>Scope</th>
                      <th>Station</th>
                      <th>Delete Design</th>
                    </tr>
                  </template>

                  <template #defaultBody="{ item: data, i }">
                    <tr v-bind:key="i" v-bind:class="{
                  'd-none': tableBody,
                }">
                      <td v-if="data.added == 0">
                        <label class="form-checkbox">
                          <input type="checkbox" :value="data.design_code +
                  '//' +
                  data.numbering +
                  '//' +
                  data.title +
                  '//' +
                  getOptionalValue(data.type, 'code') +
                  '//' +
                  getOptionalValue(data.station, 'code') +
                  '//' +
                  getOptionalValue(data.station, 'name')
                  " v-model="selectedDesign" />
                        </label>
                      </td>
                      <td v-else></td>
                      <td>{{ data.numbering }}</td>
                      <td>{{ data.title }}</td>
                      <td>{{ data.type.code }}</td>
                      <td>
                        {{ data.category != null ? data.category.name : "" }}
                      </td>
                      <td>{{ data.scope_code }}</td>
                      <td>
                        <template>
                          <div v-if="data.station == null">-</div>
                          <div v-else>
                            {{ data.station.name }}
                          </div>
                        </template>
                      </td>
                      <td v-if="data.added == 1">
                        <label class="form-checkbox">
                          <input type="checkbox" :value="data.id" v-model="selectedDeleteDesign" />
                        </label>
                      </td>
                      <td v-else></td>
                    </tr>
                  </template>
                </complete-table>

                <!-- button save -->
                <div v-bind:class="{
                  'd-none': actionDesign,
                }">
                  <div class="button-group d-flex justify-content-end">
                    <button class="btn btn-success" @click.prevent="saveDesign">
                      <div v-bind:class="{
                  'd-none': loaderSaveDesign,
                }">
                        <div class="text-center">
                          <b-spinner variant="secondary" label="Spinning" style="width: 1rem; height: 1rem"></b-spinner>
                        </div>
                      </div>
                      {{ loaderSaveDesignText }}
                    </button>

                    <button class="btn btn-danger ml-2" @click.prevent="deleteDesign">
                      <div class="loader" v-bind:class="{
                  'd-none': loaderDeleteDesign,
                }">
                        <div class="text-center">
                          <b-spinner variant="secondary" label="Spinning" style="width: 1rem; height: 1rem"></b-spinner>
                        </div>
                      </div>
                      {{ loaderDeleteDesignText }}
                    </button>
                  </div>
                </div>
                <!-- end button save -->
              </div>
            </div>
          </b-tab>
          <!-- end form tab -->
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SHOW_PROJECT_ADMIN,
  SHOW_PROJECT_DESIGN,
  GET_STATION,
  GET_SCOPE,
  GET_DESIGN,
} from "@/core/services/store/dl.design.module.js";

import { GET_CATEGORY } from "@/core/services/store/category.module.js";
import {
  SAVE_DESIGN,
  IMPORT_DESIGN,
  DELETE_DL_DESIGN,
} from "@/core/services/store/dl.design.module.js";
import { CURRENT_DESIGN_CODE } from "@/core/services/store/dl.design.module.js";
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { optionalData } from "../../../core/helper/object-validation.helper";
import readXlsxFile from "read-excel-file";
import { mapGetters } from "vuex";
import { deleteModal } from "../../../core/helper/toast.helper";
import { arrayMoreThanOne } from "@/core/helper/array-validation.helper";

export default {
  name: "design",
  components: { CompleteTable },
  data() {
    return {
      type: null,
      form: {
        owner: "",
        name: "",
        nickname: "",
        food: null,
        checked: ["not_accepted"],
      },
      selectedNickname: {
        ownerProject: null,
        nameProject: null,
        noProject: null,
        statusProject: null,
        code: null,
        nickname: null,
      },
      data: {
        exportList: [],
        kategoriOptions: [{ value: null, text: "Choose Category" }],
      },
      search: [
        {
          searchStation: "",
          searchScope: "",
          searchTitle: "",
          searchCategory: "",
          searchDesignNo: "",
        },
      ],
      selectNickname: {
        code: null,
        text: null,
      },
      selectedDesign: [],
      loaderSaveDesignText: "Simpan",
      loaderSaveDesign: true,
      loaderDeleteDesignText: "Hapus",
      loaderDeleteDesign: true,
      actionDesign: true,
      tableDesignLoader: true,
      selectedDeleteDesign: [],
      tabIndex: 1,
      tableBody: false,
      disabledSearch: true,
      disabledReset: true,
      searchButtonText: "Filter",
      loaderSearch: true,
      loaderReset: true,
      emptyTable: false,
      resetText: "Reset",
      show: true,
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Project Code",
          field: "project_code",
        },
        {
          label: "Design ",
          field: "design_code",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Type",
          field: "type_code",
        },
        {
          label: "Category",
          field: "category",
        },
        {
          label: "Scope",
          field: "scope",
        },
        {
          label: "Stasiun",
          field: "stasiun",
        },
        {
          label: "Added",
          field: "added",
        },
      ],
    };
  },
  methods: {
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;

      readXlsxFile(xlsxfile).then((rows) => {
        this.onImportData(rows);
      });
      this.selectedDeleteDesign = [];
      this.selectedDesign = [];
    },

    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },

    onImportData(listData) {
      let dataSubmit = [];
      for (let i = 1; i < listData.length; i++) {
        dataSubmit.push({
          id: listData[i][0],
          project: {
            code: listData[i][1],
            nickname: this.selectNickname.text,
          },
          design: {
            code: listData[i][2],
          },
          numbering: listData[i][3],
          title: listData[i][4],
          type: {
            code: listData[i][5],
          },
          station: {
            code: listData[i][8],
          },
          added: listData[i][9],
        });
      }

      const submitButton = this.$refs["kt_import_data"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      setTimeout(() => {
        this.$store.dispatch(IMPORT_DESIGN, { design: dataSubmit }).then(() => {
          if (this.tabIndex == 0) this.loadDesign("DOC");
          else if (this.tabIndex == 1) this.loadDesign("DRW");
          else this.loadDesign("FOR");
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },

    importData() {
      document.getElementById("fileImportExcel").click();
    },
    getOptionalValue(object, key) {
      return optionalData(object, key);
    },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async loadDesign(params) {
      this.type = params;
      const submitButton = this.$refs["kt_load_design"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.actionDesign = true;

      this.tableDesignLoader = false;
      this.emptyTable = true;
      this.tableBody = true;
      var currentCode = this.$store.getters.getterCurrentCode;
      await this.$store.dispatch(GET_DESIGN, {
        type: params,
        code: currentCode,
      });

      setTimeout(() => {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);

      this.tableBody = false;
      this.disabledSearch = false;
      this.disabledReset = false;
      this.tableBody = false;
      this.tableDesignLoader = true;
      this.actionDesign = false;

      this.mappingDataExport();
      //this.mappingDataCategory();
    },
    mappingDataCategory() {
      if (this.filteredDesign !== undefined) {
        this.data.kategoriOptions = [{ value: null, text: "Pilih Data" }];
        this.filteredDesign.map((data) => {
          if (data?.category != null) {
            if (
              this.data.kategoriOptions.filter(
                (c) => c.value === data.category.code
              ).length === 0
            ) {
              this.data.kategoriOptions.push({
                value: data?.category?.code,
                text: data?.category?.name,
              });
            }
          }
        });
      }
    },
    mappingDataExport() {
      if (this.filteredDesign !== undefined) {
        this.data.exportList = [];
        this.filteredDesign.map((data) => {
          this.data.exportList.push({
            project_code: this.selectNickname.code,
            design_code: data.design_code,
            numbering: data.numbering,
            title: data.title,
            type_code: data.type_code,
            category: data?.category?.name,
            scope: data.scope_code,
            stasiun: data.station?.code,
            added: data.added == 1 ? "added" : "not added",
            id: data.id,
          });
        });
      }
    },
    doSearch() {
      //
    },
    async resetTable() {
      var type = this.$store.getters.getterTypeDesign;
      this.loaderReset = false;
      this.resetText = "";
      await this.loadDesign(type);
      this.loaderReset = true;
      this.resetText = "Reset";
      this.search.searchStation = undefined;
      this.search.searchScope = undefined;
      this.search.searchTitle = undefined;
      this.search.searchDesignNo = undefined;
    },

    async saveDesign() {
      var valueInput = this.selectedDesign;
      var sp = [];
      for (var o = 0; o < valueInput.length; o++) {
        sp[o] = valueInput[o].split("//");
      }

      var send = [];
      // console.log("SAVE DESIGN", this.selectNickname);
      for (var i = 0; i < sp.length; i++) {
        send[i] = {
          id: null,
          project: {
            code: this.selectNickname.code,
            nickname: this.selectNickname.text,
          },
          design: {
            code: sp[i][0],
          },
          numbering: sp[i][1],
          title: sp[i][2],
          type: {
            code: sp[i][3],
          },
          station: {
            code: sp[i][4],
            name: sp[i][5],
          },
          added: "not added",
        };
      }

      var obj = {
        nickname: this.selectNickname.text,
        design: send,
      };

      this.loaderSaveDesignText = "";
      this.loaderSaveDesign = false;
      await this.$store.dispatch(SAVE_DESIGN, obj);
      this.selectedDesign = [];
      this.loaderSaveDesignText = "Simpan";
      this.loaderSaveDesign = true;
      var typeDoc = this.$store.getters.getterTypeDesign;
      this.loadDesign(typeDoc);
    },

    deleteFunction() {
      this.$store
        .dispatch(DELETE_DL_DESIGN, {
          data: {
            id: this.selectedNickname.id,
            project: {
              code: this.selectNickname.code,
              nickname: this.selectNickname.text,
            },
            designs: this.selectedDeleteDesign,
            added: "not added",
          },
        })
        .then(() => {
          this.loadDesign(this.type);
        });
    },

    deleteDesign() {
      deleteModal(this.deleteFunction);
    },
  },
  async mounted() {
    await this.$store.dispatch(SHOW_PROJECT_ADMIN);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(GET_CATEGORY);

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "DL Design Drafting" }]);
    // console.log("currentCategories", this.currentCategories);
  },

  computed: {
    ...mapGetters([
      "currentLoadingDesign",
      "getterCategory",
      "currentCategories",
    ]),

    currentNick() {
      return this.selectNickname.code;
    },

    categoriesOptions() {
      let categories = [];
      if (arrayMoreThanOne(this.currentCategories)) {
        categories = this.currentCategories.map((category) => {
          return {
            value: category.name,
            text: category.code + "-" + category.name,
          };
        });
      }

      return [{ value: null, text: "Choose Category" }, ...categories];
    },

    projectList() {
      let projectAdmin = this.$store.getters.getterProjectAdmin;
      let projectDesign = this.$store.getters.getterProjectDesign;
      return this.merge(projectAdmin, projectDesign, "code");
    },

    filteredDesign() {
      const station = this.search.searchStation;
      const scope = this.search.searchScope;
      const title = this.search.searchTitle;
      const kategori = this.search.searchCategory;
      const numbering = this.search.searchDesignNo;

      return this.$store.getters.getterDesign.designs?.filter(
        (design) =>
          (numbering
            ? design.numbering
              ?.toUpperCase()
              ?.includes(numbering?.toUpperCase())
            : true) &&
          (station ? design.station?.code === station : true) &&
          (scope ? design.scope?.code === scope : true) &&
          (kategori ? design.category?.name === kategori : true) &&
          (title
            ? design.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true)
      );
    },

    currentTab() {
      return this.tabIndex;
    },

    loadingData() {
      return this.currentLoadingDesign;
    },
  },
  watch: {
    currentNick(nick) {
      var data = this.projectList.filter((c) => c.code === nick);

      // console.log("DATA PROJECT", this.projectList);
      // console.log("DATA PROJECT", data);
      Object.assign(this.selectedNickname, {
        ownerProject: data[0].owner,
        nameProject: data[0].name,
        noProject: data[0].contract_no,
        statusProject: data[0].status,
        code: data[0].code,
        nickname: data[0].nickname,
      });

      this.$store.dispatch(CURRENT_DESIGN_CODE, { code: nick });
      this.$store.dispatch(GET_STATION, { id: nick });
      this.$store.dispatch(GET_SCOPE);
    },

    currentTab(v) {
      if (v == 0) {
        this.tableBody = true;
        this.actionDesign = true;
        this.disabledSearch = true;
        this.disabledReset = true;
      } else {
        this.disabledSearch = true;
        this.disabledReset = true;
        this.actionDesign = true;
        this.tableBody = true;
      }
    },
  },
};
</script>
